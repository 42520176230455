  <template>


        <div class="q-pa-md" style="max-width: 500px">

        <q-form 
        @submit="onSubmit"
        @reset="onReset"
        class="q-gutter-md">
        
        <span><h5>Kfz Nutzer</h5></span>
        <q-input outlined v-model="vorname" type="text" label="Vorname" lazy-rules :rules="[ val => val && val.length > 0 || 'Bitte Vornamen eingeben']" />

        <q-input outlined v-model="nachname" type="text" label="Nachname" lazy-rules :rules="[ val => val && val.length > 0 || 'Bitte Nachnamen eingeben']" />

        <q-input outlined v-model="email" type="email" label="Email" lazy-rules :rules="[ val => val && val.length > 0 || 'Bitte E-Mail Adresse eingeben']" />

      <q-input outlined v-model="password" type="password" hint="Password für die App" lazy-rules :rules="[ val => val && val.length > 5 || 'Bitte Password mit mindestens 6 Stellen eingeben']" >
      </q-input>
      <q-input outlined v-model="password2" type="password" hint="Password wiederholen" lazy-rules :rules="[val => val==password || 'Password stimmt nicht überein.']"/>

        <span><p>Welches Betriebssystem hat Dein Smartphone?</p></span>
        <q-btn-toggle
        v-model="smartphone"
        toggle-color="primary"
        :options="[
          {label: 'Apple iOS', value: 'ios'},
          {label: 'Google Android', value: 'android'},

        ]"

      ></q-btn-toggle>

              <q-separator />
        <span><h5>Fahrzeugdaten</h5></span>
         <q-input outlined v-model="hersteller" label="Hersteller" lazy-rules :rules="[ val => val && val.length > 0 || 'Bitte Hersteller eingeben']"/>    
         <q-input outlined v-model="modell" label="Modell" lazy-rules :rules="[ val => val && val.length > 0 || 'Bitte Modell eingeben']"/>  



        <q-input outlined v-model="kennzeichen" label="Kfz Kennzeichen" lazy-rules :rules="[ val => val && val.length > 0 || 'Bitte Kennzeichen eingeben']"/>  

          <q-btn type="Submit" flat color="primary" label="Absenden" />
          <q-btn label="Reset" type="reset" color="primary" flat class="q-ml-sm" />
        
        </q-form>

      </div>

    <q-dialog v-model="sentdb">
      <q-card>
        <q-card-section>
          <div class="text-h6">Hinweis</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          Daten wurden erfolgreich gespeichert. Du bekommst in den nächsten Tagen den Download Link zu Deiner iOS/Android App zugesendet. Bitte notiere Dir Dein Password, das benötigt Du für den Login in der App. Für Rückfragen sende eine E-Mail an development@dcom-online.net
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup @click="RestartPage()" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <p></p>

  </template>

  

<script>
// import {useQuasar} from 'quasar'  
// import { Notify } from 'quasar'
import { ref } from 'vue'
// import { date } from 'quasar'
import db from '/src/components/db.js'
// import moment from 'moment'



export default {

  setup () {

//  const $q = useQuasar()
   const vorname = ref(null)
   const nachname = ref(null)
   const email = ref(null)
   const password = ref(null)
   const password2 = ref(null)
   const smartphone = ref('ios')
   const hersteller= ref(null)
   const modell = ref(null)
   const kennzeichen = ref(null)
   const sentdb = ref(false)
    return {
      vorname,
      nachname,
      email, 
      password,
      password2, 
      smartphone,
      hersteller,
      modell,
      kennzeichen,
      sentdb,

      


      onSubmit () {
        
        const KfzNutzer = {
        vorname: vorname.value,
        nachname: nachname.value,
        email: email.value,
        password: password.value,
        smartphone: smartphone.value,
        hersteller: hersteller.value,
        modell: modell.value,
        kennzeichen: kennzeichen.value
        }
        
        db.collection('KfzNutzer').add(KfzNutzer).then(() => { 

          alert("Danke, die Daten wurden erfolgreich übermittelt. Du bekommst die Tage eine E-Mail mit dem Link zu der DCOM Fleet Management App");
          
          vorname.value = null,
          nachname.value = null,
          email.value = null,
          password.value = null,
          password2.value = null,
          smartphone.value ='ios',
          hersteller.value = null,
          modell.value = null,
          kennzeichen.value = null  

        })




      },

      onReset () {
        vorname.value = null
        nachname.value = null
        email.value = null,
        password.value = null,
        password2.value = null,
        smartphone.value ='ios',
        hersteller.value = null,
        modell.value = null,
        kennzeichen.value = null
      }
    }
  }
}
</script>
