import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBJjTM0r4He7mjXEbpxqDJnpqw6rIplU04",
  authDomain: "dcom-dkv-tool.firebaseapp.com",
  projectId: "dcom-dkv-tool",
  storageBucket: "dcom-dkv-tool.appspot.com",
  messagingSenderId: "549311829524",
  appId: "1:549311829524:web:55bb9e035987ece1f67901",
  measurementId: "G-GYYWFWFVPD"
};
  // Initialize Firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    }
  const db = firebase.firestore();

  db.settings({ timestampsInSnapshots: true})

  export default db;