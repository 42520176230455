<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated class="glossy">
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        />

        <q-toolbar-title>
          DCOM FLEET MANAGEMENT | Registrierung Kfz Nutzer
        </q-toolbar-title>

        <div>1.0</div>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      class="bg-grey-2"
    >
      <q-list>
        <q-item-label header>DCOM GmbH / internal</q-item-label>
        <q-item clickable tag="a" target="_blank" href="https://dcom-online.net">
          <q-item-section avatar>
            <q-icon name="school" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Homepage</q-item-label>
          </q-item-section>
        </q-item>

      </q-list>
    </q-drawer>

    <q-page-container>
      <KfzRegistrierung />
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from 'vue'
import KfzRegistrierung from './components/KfzRegistrierung.vue'

export default {
  name: 'LayoutDefault',

  components: {
    KfzRegistrierung
  },

  setup () {
    return {
      leftDrawerOpen: ref(false)
    }
  }
}
</script>
